<template>
  <div class="container">
    <van-address-list
      v-model="chosenAddressId"
      :list="list"
      :switchable="isChoose"
      default-tag-text="默认"
      @add="onAdd"
      @edit="onEdit"
      @select="onSelect"
    >
      <div v-if="!list.length" slot="top" class="no-data">
        <van-empty
          :image="no_data"
          :description="noTips"
        />
      </div>
    </van-address-list>
  </div>
</template>

<script>
import { AddressList } from 'vant'
let flag
import storage from 'good-storage'
import { Empty } from 'vant'
import no_data from '@/assets/no_data.png'

export default {
  name: 'AddressList',
  components: {
    [AddressList.name]: AddressList,
    [Empty.name]: Empty
  },
  data() {
    return {
      chosenAddressId: '',
      list: [],
      noTips: '您还没有地址信息',
      no_data,
      isChoose: false
    }
  },
  created() {
    this.isChoose = !!this.$route.query.isChoose
    flag = false
    this.$toast.loading({
      message: '加载中...',
      forbidClick: true
    })
    this.$api.address_index().then(res => {
      this.$toast.clear()
      const list = res.data
      this.list = list.map(item => ({
        id: item.aid,
        name: item.name,
        tel: item.phone,
        address: `${item.province}${item.city}${item.dist}${item.addrs}`,
        isDefault: !!item.default_addr
      }))
    })
  },
  methods: {
    onAdd() {
      this.$router.push({ path: 'address-edit' })
    },
    onEdit(item) {
      this.$router.push({ path: 'address-edit', query: { id: item.id }})
    },
    onSelect(item) {
      console.log(item)
      if (!this.isChoose) return
      // ?会触发2次方法
      if (!flag) {
        flag = true
        storage.session.set('choseAddress', item)
        this.$router.back()
      }
    }
  }
}
</script>

<style lang="less" scoped>
  /deep/ .van-button--danger{
    background-color: @ui-color;
    border: 1px solid @ui-color;
  }
</style>

